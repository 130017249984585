import { getFunc, postFunc, putFunc } from "../httpService";

export const baseurl = window._env_
  ? window._env_.BASE_AUTH_URL
  : "https://uber-for-diesel-auth-service.k8.isw.la/u4da/api/";
const client_id = window._env_
  ? window._env_.CUSTOMER_CLIENT_ID
  : "smartfuelcustomer";
const client_secret = window._env_
  ? window._env_.CUSTOMER_CLIENT_KEY
  : "60F05D7B-1D48-42D5-AE3A-B82C46E82ADF";

// Reports
export const reportUrl = window._env_
  ? window._env_.BASE_REPORT_URL
  : "https://uber4diesel-report-service.k8.isw.la/uber4diesel/api/";

// Signup User
export const onboardSignupService = (payload) => {
  return postFunc(`${baseurl}Auth/CreateNewUser`, payload);
};

// verify-user
export const onboardVerifyUserService = (payload) => {
  return getFunc(
    `${baseurl}Auth/ConfirmEmail?userId=${encodeURIComponent(
      payload?.userId
    )}&token=${encodeURIComponent(payload?.token)}`
  );
};
