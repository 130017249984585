import { qs } from "./authServices";
import { getFunc, postFunc } from "./httpService";
import { ENCRYPT_USER } from "../arsVariables";
import { decryptAndRead } from "./localStorageHelper";

export const baseOrderUrl = window._env_
  ? window._env_.BASE_ORDER_URL
  : "https://uber-for-diesel-ordermgmt-service.k8.isw.la/u4do/api/";
//: "https://feulorder.azurewebsites.net/api/";

// get vendor product/contract detail
export const getProdnContDetailService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetContractDetail?${qs.stringify(payload)}`
  );
};

// get vendors n their products
export const getVendorWithProductsService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Vendor/GetVendorsWithContracts?${qs.stringify(payload)}`
  );
};

// get product from vendor
export const getVendorPrdouctsService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetVendorProducts?${qs.stringify(payload)}`
  );
};

export const getContractHistoryService = ({
  payload: { contractId, ...rest },
}) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetContractHistory/${contractId}?${qs.stringify(
      rest
    )}`
  );
};

// get vendors n their contracts
export const getVendorWithContractsService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Vendor/GetVendorsWithContracts?${qs.stringify(payload)}`
  );
};

// get contract List
export const getContractList = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetContractList?${qs.stringify(payload)}`
  );
};

// get vendors n their contracts
export const getVendorWithContractDetailService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetContractWithVendor?${qs.stringify(payload)}`
  );
};

// create order
export const createOrderService = ({ payload }) => {
  return postFunc(`${baseOrderUrl}Customer/CreateOrder`, payload);
};

// bulk upload orders
export const bulkUploadOrderService = ({ payload }) => {
  return postFunc(`${baseOrderUrl}customer/uploadorders`, payload);
};

// get pending order
export const getPendingOrderService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetPendingOrdersForDashboard?${qs.stringify(
      payload
    )}`
  );
};

// get branch order
export const getBranchOrderService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Analytics/CustomerBranchOrders?${qs.stringify(payload)}`
  );
};

// get branch fulfilled order
export const getBranchFulfilledOrderService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Analytics/CustomerFulfilledOrders?${qs.stringify(payload)}`
  );
};

//get recent branch order
export const getBranchRecentOrderService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Analytics/TopCustomersRecentOrders?${qs.stringify(payload)}`
  );
};
// GET CUSTOMER ORDER DELIVERIES
export const getCustomerOrderDeliveriesService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Analytics/CustomerOrderDelivery?${qs.stringify(payload)}`
  );
};

// get orderitems
export const getOrderItemsService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetOrderItems?${qs.stringify(payload)}`
  );
};

// get order history of users
export const getOrderHistoryService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Customer/GetOrderItemsByUser?${qs.stringify(payload)}`
  );
};

// get orders
export const getOrdersService = ({ payload }) => {
  return getFunc(`${baseOrderUrl}Customer/GetOrders?${qs.stringify(payload)}`);
};

// get order
export const getOrderService = ({ payload }) => {
  return getFunc(`${baseOrderUrl}Customer/GetOrder?${qs.stringify(payload)}`);
};

// verify/decline order
export const verifyOrderService = ({ payload }) => {
  return postFunc(`${baseOrderUrl}Customer/ApproveOrDeclineOrder`, payload);
};

// approve/decline order
export const reactToOrderService = ({ payload }) => {
  return postFunc(
    `${baseOrderUrl}Customer/ApproveOrDeclineOrderFinal`,
    payload
  );
};

// approve/decline order
export const requestContractService = ({ payload }) => {
  return postFunc(`${baseOrderUrl}Customer/ContractRequest`, payload);
};

// get deliveries
export const getDeliveriesService = ({ payload }) => {
  return getFunc(
    `${baseOrderUrl}Delivery/GetDeliveryCustomer?${qs.stringify(payload)}`
  );
};

// submit verification code
export const submitVerificationCodeService = ({ payload }) => {
  return postFunc(`${baseOrderUrl}Customer/AcceptDeliveredOrder`, payload);
};

// submit verification code
export const getGraphDataService = (payload) => {
  return getFunc(
    `${baseOrderUrl}Statistics/GetCustomerOrderLocationVisualizerStatistics?customerId=${
      // jwtDecode(decryptAndRead(ENCRYPT_USER).access_token).sub
      (decryptAndRead(ENCRYPT_USER) || { decoded: { sub: "0000-000" } }).decoded
        .sub
    }&${qs.stringify(payload)}`
  );
};
