import React, { Component } from 'react';
import { Modal, Col, Row } from "antd";
import { List } from "@material-ui/icons";
// import { history } from "./history";

const QUICKTELLER_FUEL = "QUICKTELLER_FUEL";
const CONTRACT_MANAGEMENT = "CONTRACT_MANAGEMENT";
const FORECOURT_AUTOMATION = "FORECOURT_AUTOMATION";

class ProductSwitch extends Component {
  state = {
    isShow: false,
  };

  showMenu = (event) => {
    event.preventDefault();

    this.setState({ isShow: true });
  }

  closeMenu = () => {
    this.setState({ isShow: false });
  }

  render() {
    const { modules = [] } = this.props;

    const FRONT_SSO_URL = window._env_.FRONT_SSO_URL;
    const canUseQuicktFuelModule = modules.includes(QUICKTELLER_FUEL);
    const canUseContractMgtModule = modules.includes(CONTRACT_MANAGEMENT);
    const canUseForecourtAModule = modules.includes(FORECOURT_AUTOMATION);

    return (
      <>
        <button type="button" onClick={this.showMenu} id="product-switch-btn" style={{
          backgroundColor: '#ffffff',
          // marginLeft: '50px',
          marginRight: '30px',
          border: 'none'
        }}>
          <List fontSize="large" />
        </button>

        <div id="product-switch-menu">
          <Modal
            style={{ position: "fixed", top: "4.8rem", right: 5, marginRight: 5 }}
            width={330}
            title="Modules"
            visible={this.state.isShow}
            centered={false}
            closable={false}
            maskClosable={true}
            onCancel={this.closeMenu}
            footer={null}
          >
            <div>
              <div style={{ height: 16, width: 16, background: '#ffffff', transform: `rotate(45deg)`, position: 'absolute', top: -8, right: 35, borderRadius: 4 }}></div>
              {/* <p style={{ color: '#000000', fontWeight: 'bold' }}>Modules</p> */}
              <Row type="flex" justify="space-between">
                <Col xs={{ span: 11 }} style={{ marginBottom: 20, cursor: "pointer" }}>
                  <div
                    style={{
                      opacity: !canUseQuicktFuelModule ? 0.3 : 1,
                    }}
                    onClick={() => {
                      if (canUseQuicktFuelModule) window.location = `${FRONT_SSO_URL}/delivery`;
                    }}
                  >
                    {/* <a href={`${FRONT_SSO_URL}/delivery`}> */}
                    <img
                      src={"/images/delivery.jpeg"} alt=""
                      style={{ width: "100%", borderRadius: 4 }}
                    />
                    <span
                      style={{
                        fontSize: 13,
                        lineHeight: "18px",
                        marginTop: 12,
                        display: "block",
                        color: "#444444",
                      }}
                    >
                      Quickteller Fuel Delivery
                    </span>
                    {/* </a> */}
                  </div>
                </Col>
                <Col xs={{ span: 11 }} style={{ marginBottom: 20, cursor: "pointer" }}>
                  <div
                    style={{
                      opacity: !canUseContractMgtModule ? 0.3 : 1,
                    }}
                    onClick={() => {
                      if (canUseContractMgtModule) window.location = `${FRONT_SSO_URL}/contract`;
                    }}
                  >
                    {/* <a href={`${FRONT_SSO_URL}/contract`}> */}
                    <img
                      src={"/images/contract.jpeg"} alt=""
                      style={{ width: "100%", borderRadius: 4 }}
                    />
                    <span
                      style={{
                        fontSize: 13,
                        lineHeight: "18px",
                        marginTop: 12,
                        display: "block",
                        color: "#444444",
                      }}
                    >
                      Contract Management
                    </span>
                    {/* </a> */}
                  </div>
                </Col>
                <Col xs={{ span: 11 }} style={{ marginBottom: 20, cursor: "pointer" }}>
                  <div
                    style={{
                      opacity: !canUseForecourtAModule ? 0.3 : 1,
                    }}
                    onClick={() => {
                      if (canUseForecourtAModule) window.location = `${FRONT_SSO_URL}/forecourt`;
                    }}
                  >
                    {/* <a href={`${FRONT_SSO_URL}/forecourt`}> */}
                    <img
                      src={"/images/forecourt.jpeg"} alt=""
                      style={{
                        width: "100%",
                        borderRadius: 4,
                      }}
                    />
                    <span
                      style={{
                        fontSize: 13,
                        lineHeight: "18px",
                        marginTop: 12,
                        display: "block",
                        color: "#444444",
                      }}
                    >
                      Forecourt Automation
                    </span>
                    {/* </a> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default ProductSwitch;