import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createStore from "./create-store";
import App from "./App";
import { ErrorHandler } from "./reuse/error";
import * as serviceWorker from "./serviceWorker";

// require('dotenv').config();

export const store =
  process.env.NODE_ENV === "development"
    ? createStore(
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : createStore();

const rootId = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ErrorHandler>
      <App />
    </ErrorHandler>
  </Provider>,
  rootId
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
