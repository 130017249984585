import { qs } from "./authServices";
import { getFunc } from "./httpService";

const baseurlOrder = window._env_ ? window._env_.BASE_ORDER_URL : "https://uber-for-diesel-ordermgmt-service.k8.isw.la/u4do/api/"
//: "https://feulorder.azurewebsites.net/api/";

const baseurl = window._env_ ? window._env_.BASE_AUTH_URL : "https://uber-for-diesel-auth-service.k8.isw.la/u4da/api/"
//"https://feulapp.azurewebsites.net/api/";

// get customer order stats
export const getCustomerOrderStatsService = ({ payload }) => {
  return getFunc(
    `${baseurlOrder}Statistics/GetCustomerOrderStatistics?${qs.stringify(
      payload
    )}`
  );
};
// get customer profile stats
export const getCustomerProfileStatsService = () => {
  return getFunc(`${baseurl}Statistics/GetCustomerProfileStatistics`);
};
