// import createStore from "./create-store";

export const url = "/customer/";
export const FRONT_SSO_URL = window._env_ ? window._env_.FRONT_SSO_URL : "";

// export const store =
//     process.env.NODE_ENV === "development"
//         ? createStore(
//             window.__REDUX_DEVTOOLS_EXTENSION__ &&
//             window.__REDUX_DEVTOOLS_EXTENSION__()
//         )
//         : createStore();
